import React , {useState, useEffect} from "react";
import Navbar from "../../Components/Header/Header";
import "./About.scss";
import Button from "../../Components/Button/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faUserGroup, faBed, faChevronRight, faCarOn, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {ENDPOINTS, apiCall} from '../../Lib/App';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import video2 from "../../Assets/video1.mp4";
import placeholder from "../../Assets/placeholder2.png";



const About = () => {
  const { t, i18n } = useTranslation();
  const ArrowLeft = (props) => (
    <button {...props} className={"prev-arrow2"}>
      {" "}
      <FontAwesomeIcon icon={faAngleLeft} />
    </button>
  );
  const ArrowRight = (props) => (
    <button {...props} className={"next-arrow2"}>
      {" "}
      <FontAwesomeIcon icon={faAngleRight} />
    </button>
  );



  const [villas, setVillas] = useState([]);


  useEffect(() => {
    fetchVillas();
  }, []);

  const fetchVillas = async () => {
    try {
      const endpoint = ENDPOINTS.getAllVillas;
      const response = await apiCall(endpoint);
      if (response && Array.isArray(response.villas)) {
        const parsedVillas = response.villas.map(villa => ({
          ...villa,
          // images: JSON.parse(villa.images || "[]")  
        }));
        setVillas(parsedVillas);
      } else {
        setVillas([]);
      }
    } catch (error) {
      console.error("Failed to fetch villas", error);
      setVillas([]);
    }
  }

  const aboutVillaSettings = {
    dots: true,
    fade: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: false,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  };
  const navigate = useNavigate();

  const navigateBook = () =>{
    navigate("/rooms");
  }




  const serviceData = [
    {
      title: t("airportPickUpService"),
      description: t("airportPickUpServiceDescription"),
      icon: faCarOn
    },
    {
      title: t("exclusiveGuidedTours"),
      description: t("exclusiveGuidedToursDescription"),
      icon: faCarOn
    },
    {
      title: t("inRoomDining"),
      description: t("inRoomDiningDescription"),
      icon: faCarOn
    },
    {
      title: t("spaAndWellness"),
      description: t("spaAndWellnessDescription"),
      icon: faCarOn
    },
    {
      title: t("adventureActivities"),
      description: t("adventureActivitiesDescription"),
      icon: faCarOn
    },
    {
      title: t("conciergeServices"),
      description: t("conciergeServicesDescription"),
      icon: faCarOn
    }
  ];

  console.log(villas)
  return (
    <div>
      <Navbar />
      <section className="about_hero">
  <video
    className="about_hero_video"
    autoPlay
    loop
    muted
    playsInline
    preload="auto"
    poster={placeholder}
      >
    <source src={video2} type="video/mp4" />
    <img
      src={placeholder}
      alt="Background placeholder"
      className="about_hero_placeholder"
    />
    Your browser does not support the video tag.
  </video>
  <div className="container">
    <div className="about_hero_content">
      <span>{t("aboutTitle")}</span>
      <h2>{t("aboutBujtina")}</h2>
      <p>{t("aboutText")}</p>
    </div>
  </div>
</section>


      <section className="about_welcome">
        <div className="container">
          <div className="about_welcome-wrapper">
            <span>{t("welcome")}</span>
            <h2>{t("aboutWelcome")}</h2>
<p>
{t("roomExperienceText")}
</p>

            <div className="about_welcome-container">
              <div className="about_welcome-box" style={{ backgroundImage: "url(https://cf.bstatic.com/xdata/images/hotel/max1024x768/566619675.jpg?k=c604b0494745c3a395f780df83f8459f7a943a9dae7108f602d495111649a6d1&o=&hp=1)" }}></div>
              <div className="about_welcome-box" style={{ backgroundImage: "url(https://cf.bstatic.com/xdata/images/hotel/max1024x768/579542437.jpg?k=1222926daa83429a35aa6b1561dc2b557b1e7b68cd2e2c076bda78e5bd14aae2&o=&hp=1)" }}></div>
              <div className="about_welcome-box" style={{ backgroundImage: "url(https://cf.bstatic.com/xdata/images/hotel/max1024x768/579542738.jpg?k=6b1926de93a27659357f75057a6d13afc50eef181e30afa05b08d429dec3bf1b&o=&hp=1)" }}></div>
            </div>
          </div>
        </div>
      </section>
      <section className="about_counting" style={{ backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(https://cf.bstatic.com/xdata/images/hotel/max1024x768/579510801.jpg?k=fd5b590658e8535f31f6672674ef7631a0a62ad49ea75f28f9cd5ba60f607660&o=)" }}>
        <div className="container">
          <div className="about_counting-wrapper">
            <span>{t("aboutExperience")}</span>
            <h2>{t("aboutExperienceText")}</h2>
            <div className="about_counting-list">
              <div className="about_counting-item">
                <span>8</span>
                <p>{t("rooms")}</p>
              </div>
              <div className="about_counting-item">
                <span>3</span>
                <p>{t("years")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about_book">
        <div className="container">
          <div className="about_book_container">
            <div className="about_book_left">
            <span>{t("aboutBook1Title")}</span>
<h2>{t("aboutBook1Header")}</h2>
<p>
{t("aboutBook1Text")}
</p>

              <Button className="main-btn" onClick={navigateBook} icon="" buttonText={t("bookYourStay")} />
            </div>
            <div className="about_book_right">
              
              <Slider {...aboutVillaSettings}>
                {villas.map((card, index) => {
 const language = i18n.language; 
 const description = card.description[language] || card.description['en'];
 const name = card.name[language] || card.name['en'];
 const truncatedDescription = description.length > 120
   ? `${description.substring(0, 120)}...`
   : description;
return (
                    
                          <Link to={`/roomDetails/${card.villa_id}`}  state={{ villaData:card }}  key={index} className="room_villa_box">
                  <div key={index}>
                    <div className="item-card">
                      <div className="room_similar_price"  style={{color:"black"}}>{card.price_per_night}€</div>
                      <div className="item-card-hover" style={{    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('${card.images[0]}')`, }}>
                        <div className="card-image">
                          <div className="hover-content">
                            <div className="hover-text">
                              <p>{card.price_per_night}€</p>
                              <p>{truncatedDescription}</p>
                              <a href="#" className="discover-more">
                                Discover More <FontAwesomeIcon icon={faChevronRight} />
                              </a>
                            </div>
                          </div>
                          <div className="card-details">
                            <h2>{name}</h2>
                            <div className="about_villa_info">
                              <div className="about_villa_info-item">
                                <FontAwesomeIcon icon={faExpand} />
                                <span>{card.area_size}</span>
                              </div>
                              <div className="about_villa_info-item">
                                <FontAwesomeIcon icon={faUserGroup} />
                                <span>{card.max_guests}</span>
                              </div>
                              <div className="about_villa_info-item">
                                <FontAwesomeIcon icon={faBed} />
                                <span>{card.number_of_bedrooms}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
)
})}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section className="about_experience">
        <div className="container">
          <div className="about_experience_container">
            <div className="about_experience_left">
              <div>
                <img src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/579510862.jpg?k=5a0988be3eda579cecd97ab9ca66827548e2bee6c01f5258f700cc8de02e4602&o=" />
              </div>
              <div>
                <img src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/566619675.jpg?k=9f3358270c990c028fbbc8002139b13d827afff94be7b9d14b5abf8def3deeb2&o=" />
              </div>
            </div>
            <div className="about_experience_right">
            <span>{t("aboutBook2Title")}</span>
<h2>{t("aboutBook2Header")}</h2>
<p>
{t("aboutBook2Text")}
</p>

              <Button className="main-btn" onClick={navigateBook} icon="" buttonText={t("bookYourStay")} />
            </div>
          </div>
        </div>
      </section>
      {/* <section className="about_services">
        <div className="container">
          <div className="about_services_container">
            <div className="about_services_top">
            <span>{t("welcome")}</span>
<h2>{t("aboutWelcomeBottom")}</h2>
<p> {t("footerText")}
</p>

            </div>

            <div className="about_services-list">
              {serviceData.map((item, index) => (
                <div className="cs-info-box-item" key={index}>
                  <div className="cs-item-container">
                    <div className="cs-info-box-img">
                      <FontAwesomeIcon icon={item.icon} />
                    </div>
                    <div className="cs-info-box-content">
                      <h5 className="cs-title cs-info-box-title">{item.title}</h5>
                      <div className="cs-info-box-text">
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>"years"
          </div>
        </div>
      </section> */}
  

      <section className="about_discover">
        <div className="about_discover_container">
          <div className="about_discover_item">
            <div className="about_discover_item_content white">
              <span>{t("rooms")}</span>
              <h2>{t("aboutBook2Header")}</h2>
              <p>{t("aboutText")}</p>
              <a href="/rooms" className="discover-more">
              {t("discoverMore")}
              </a>
            </div>
            <div className="about_discover_item_content" style={{ backgroundImage: "url(https://cf.bstatic.com/xdata/images/hotel/max1024x768/566849008.jpg?k=2dff19ca93dbdc4bc33ce68fc5956ed9ab2bfed3eb6325e548b2af97c8934547&o=)" }}></div>
          </div>
          <div className="about_discover_item" style={{ backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(https://cf.bstatic.com/xdata/images/hotel/max1024x768/579500500.jpg?k=101d35764d562868be2bcb9b221b509421ac3c5c99eefb95fb92880273e9afc8&o=)" }}>
            <div className="about_discover_item discover_text">
            {/* <span>{t("gastronomy")}</span>
              <h2>{t("resBars")}</h2>
              <p>{t("aboutDiscover")}</p>
              <a href="#" className="discover-more">
              {t("discoverMore")} */}
              {/* </a> */}
            </div>
          </div>
          <div className="about_discover_item">
            <div className="about_discover_item_content white">
            <span>{t("rooms")}</span>
              <h2>{t("aboutBook1Header")}</h2>
              <p>{t("aboutText")}</p>
              <a href="/rooms" className="discover-more">
              {t("discoverMore")}
              </a>
            </div>
            <div className="about_discover_item_content" style={{ backgroundImage: "url(https://cf.bstatic.com/xdata/images/hotel/max1024x768/579510801.jpg?k=fd5b590658e8535f31f6672674ef7631a0a62ad49ea75f28f9cd5ba60f607660&o=)" }}></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
