import React from 'react';
import { useTranslation } from 'react-i18next';
import './HouseRules.scss';

const HouseRules = () => {
  const { t } = useTranslation();
  const propertyName = 'Bujtinat Lisa'; // Property name used in translation

  return (
    <div className="house-rules">
      <div className='house-top'>
        <h2>{t('houseRulesTitle')}</h2>
        <p>
          <strong>{propertyName}</strong> {t('houseRulesSpecialRequests', { propertyName })}
        </p>
      </div>
      <div className="rule-section">
        <div className="rule-item">
          <span role="img" aria-label="check-in">🕓</span>
          <div>
            <h3>{t('checkInTitle')}</h3>
            <p>{t('checkInDescription')}</p>
          </div>
        </div>
        <div className="rule-item">
          <span role="img" aria-label="check-out">🕗</span>
          <div>
            <h3>{t('checkOutTitle')}</h3>
            <p>{t('checkOutDescription')}</p>
          </div>
        </div>
        <div className="rule-item">
          <span role="img" aria-label="cancellation">❌</span>
          <div>
            <h3>{t('cancellationTitle')}</h3>
            <p>
              {t('cancellationDescription', { enterDatesLink: <a href="#">{t('enterDatesLink')}</a> })}
            </p>
          </div>
        </div>
        <div className="rule-item">
          <span role="img" aria-label="children-beds">🛏️</span>
          <div>
            <h3>{t('childrenBedsTitle')}</h3>
            <p>
              <strong>{t('childPolicies')}</strong><br />
              {t('childPoliciesDescription')}
            </p>
            <p>
              <strong>{t('cotAndExtraBedPolicies')}</strong><br />
              {t('cotAndExtraBedDescription')}
            </p>
          </div>
        </div>
        <div className="rule-item">
          <span role="img" aria-label="no-age">🔞</span>
          <div>
            <h3>{t('noAgeRestrictionTitle')}</h3>
            <p>{t('noAgeRestrictionDescription')}</p>
          </div>
        </div>
        <div className="rule-item">
          <span role="img" aria-label="cash-only">💵</span>
          <div>
            <h3>{t('cashOnlyTitle')}</h3>
            <p>{t('cashOnlyDescription')}</p>
          </div>
        </div>
        <div className="rule-item">
          <span role="img" aria-label="no-smoking">🚭</span>
          <div>
            <h3>{t('smokingTitle')}</h3>
            <p>{t('smokingDescription')}</p>
          </div>
        </div>
        <div className="rule-item">
          <span role="img" aria-label="no-parties">🎉</span>
          <div>
            <h3>{t('partiesTitle')}</h3>
            <p>{t('partiesDescription')}</p>
          </div>
        </div>
        <div className="rule-item">
          <span role="img" aria-label="quiet-hours">🔕</span>
          <div>
            <h3>{t('quietHoursTitle')}</h3>
            <p>{t('quietHoursDescription')}</p>
          </div>
        </div>
        <div className="rule-item">
          <span role="img" aria-label="pets">🐕</span>
          <div>
            <h3>{t('petsTitle')}</h3>
            <p>{t('petsDescription')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HouseRules;
