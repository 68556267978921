import React from 'react';
import './Footer.scss'; 
import { useTranslation } from "react-i18next";
import instagram from "../../../Assets/instagram.png";
import fb from "../../../Assets/social.png";
import tiktok from "../../../Assets/tiktok.png";



const Footer = () => {
  const { t, i18n} = useTranslation();
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-section">
          <h2>Bujtinat Lisa</h2>
          <p>
          {t("footerText")}
          </p>
          <div className="social-icons">
            <a href="https://www.instagram.com/bujtinat.lisa/?hl=en" target="_blank"><img src={instagram} /></a> 
             <a href="https://www.facebook.com/p/Bujtinat-LISA-100088530400800/" target="_blank"><img src={fb} /></a>
             <a href="https://www.tiktok.com/@bujtinat.lisa" target="_blank"><img src={tiktok} /></a>
          </div>
        </div>

        <div className="footer-section footer-ideaa">
          <h3>{t("aboutUs")}</h3>
          <ul>
            <li><a href="/about"> {t("about")}</a></li>
            <li><a href="/rooms"> {t("rooms")}</a></li>
            <li><a href="/blog">Blog</a></li>
            <li><a href="/faq">Faq</a></li>
          </ul>
        </div>

        {/* <div className="footer-section">
          <h3>Experiences</h3>
          <ul>
            <li><a href="#">Dining</a></li>
            <li><a href="#">Spa & Wellness</a></li>
            <li><a href="#">Local Activities</a></li>
            <li><a href="#">Meetings</a></li>
            <li><a href="#">Our Menu</a></li>
          </ul>
        </div> */}

<div className="footer-section">
  <h3>{t("reach")}</h3>
  <address>
    Azem Shkreli 70, 33000, Shkrel, Kosovo<br />
  </address>
  <a 
    href="https://www.google.com/maps/place/Bujtinat+LISA/@42.740444,20.0740247,17z/data=!3m1!4b1!4m5!3m4!1s0x1352f117bfaf4eaf:0x2bebb7dc5f4c3b8f!8m2!3d42.7404444!4d20.0740247" 
    className="get-directions" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    {t("direction")}
  </a>
</div>

      </div>
    </footer>
  );
};

export default Footer;