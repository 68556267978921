import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    resources: {
      en: {
        translation: {
          "years":"Years History",
          "v1":"A serene escape featuring spacious rooms and modern comforts, perfect for a peaceful getaway",
          "v3":"A contemporary villa with sleek design and open living spaces, providing all the comforts for a luxurious vacation",
          "v2":"A cozy villa with stunning views and all essential amenities, offering a tranquil atmosphere for relaxation",
          
          "v1":"A serene escape featuring spacious rooms and modern comforts, perfect for a peaceful getaway",
          "v3":"A contemporary villa with sleek design and open living spaces, providing all the comforts for a luxurious vacation",
          "v2":"A cozy villa with stunning views and all essential amenities, offering a tranquil atmosphere for relaxation",
          
          "welcomeMessage": "Welcome",
          "welcome": "Welcome to Bujtinat Lisa",
          "testimonial1": {
            "content": "Fabulous place to stay with a family. Loved the design of the cabin Lovely views of the mountains, forest. Loved the balcony and the barbeque. Nice bedrooms and comfortable living, dining place",
            "label": "Eveli"
          },
          "testimonial2": {
            "content": "Very nice accommodation, very clean. A bit too small for 8 people, they have 2 double beds with a pull-out extra bed, i.e. 6 people sleep comfortably there.",
            "label": "Kukaj"
          },
          "testimonial3": {
            "content": "Very nice accommodation, very clean. A bit too small for 8 people, they have 2 double beds with a pull-out extra bed, i.e. 6 people sleep comfortably there.",
            "label": "Pascal"
          },
          "heroText": "Your forest paradise of warmth, tranquility, and restoration awaits.",
          "enquireNow": "Enquire now",
          "submit":"Submit",
          "totalCost":"Total Cost",
          "checkIn": "Check In",
          "checkOut": "Check Out",
          "mainServices":"Main Services",
          "checkInTime":"Check In Time",
          "checkOutTime":"Check Out Time",
          "outdoor":"Outdoor",
          "roomsComfortable":"Jacuzzi and Comfortable Stay Experience",
          "roomsComfortableText":"For the comfort of all our guests, all our facilities and accommodations are 100% smoke-free – regardless of substance or equipment. All rooms include cable T.V., a mini fridge, a coffeemaker, a microwave, and complimentary linens and towels.",
          "ecl":"Explore Cabins & Lodges",
          "roomsOffering":"Offering our guests a magical atmosphere with modern conveniences",
          "roomsViews":"Rooms and Views",
          "checkAvailability": "Check Availability",
          "airportPickUpService": "Airport Pick-up Service",
          "airportPickUpDescription": "Experience seamless travel with our dedicated airport pick-up service, ensuring your journey to our haven is as comfortable as your stay.",
          "exclusiveGuidedTours": "Exclusive Guided Tours",
          "exclusiveGuidedToursDescription": "Immerse yourself in the local culture and breathtaking landscapes with our personalized guided tours, tailored to your interests.",
          "inRoomDining": "In-Room Dining",
          "inRoomDiningDescription": "Enjoy a fine dining experience in the comfort of your room with our extensive menu, featuring local and international delicacies.",
          "spaAndWellness": "Spa and Wellness",
          "spaAndWellnessDescription": "Relax and rejuvenate with our spa services, offering a range of treatments designed to enhance your well-being.",
          "adventureActivities": "Adventure Activities",
          "adventureActivitiesDescription": "Explore the great outdoors with our curated adventure activities, perfect for thrill-seekers and nature lovers alike.",
          "conciergeServices": "Concierge Services",
          "conciergeServicesDescription": "Our concierge team is here to assist you with all your needs, from restaurant reservations to event planning.",
          "moreThanJustAMountainView": "MORE THAN JUST A MOUNTAIN VIEW",
          "unforgettableExperiencesAwait": "Unforgettable Experiences Await",
          "discoverMore": "Discover more",
          "selectYourVilla": "Select Your Cabin",
          "enjoyAWorldClassStay": "ENJOY A WORLD-CLASS STAY",
          "discoverAllActivities": "Discover All Activities",
          "exploreTheHotel": "Explore the hotel",
          "homeWelcome":"Ine the Heart of Shkrel",
          "escapeToMountain":"Escape to the majestic mountains and valleys where your dreams become reality. Immerse yourself in the serenity of nature, far from the hustle and bustle of daily life.",
          "outdoorAdventures":"Outdoor Adventures",
          "mainActivityVilla":"Main Activity Cabin",
          "roomExperience":" Experience Breathtaking Mountain Views from Our Private Cabin",
          "ShkrelHikingTrails":"With over 1,600 kilometers of trails, Shkrel offers some of the most breathtaking hikes in the region.",
          "roomExperienceText":"Nestled in the picturesque Shkrel, Rugove, Republic of Kosovo, Bujtinat Lisa offers a serene retreat in the heart of nature. Surrounded by lush landscapes and towering mountains, our cabins provide a perfect escape for relaxation and rejuvenation, with stunning views that capture the beauty of the Rugova Valley.",
          "natureEscapes":"Nature Escapes",
      "villaRetreat":"Cabin Retreat",
      "curatedNatureTrips":"Discover the beauty of nature with our curated trips, tailored to showcase the best of the local environment.",
      "adventureAwaits":"Adventure Awaits",
      "ShkrelAdventurePackages":"Explore the rugged beauty of Shkrel with our adventure packages, perfect for thrill-seekers.",
      "ultimateMountainExperience":"Every detail within our Cabin is designed to provide you with the ultimate mountain experience, where luxury meets nature.",
      "about":"About",
      "rooms":"Cabins",
      "rooms":"Cabins",
      "home" :"Home",
      "reach":"Reach out",
      "direction":"Get direction",
      "selectDate":"Select Date",
      "checkAccomodotion":"Check Our Cabins",
      "rsc":"Rooms, Views & Cabins",
      "aboutUs":"About Us",
      "aboutTitle":"AN ICONIC CABIN PLACE",
      "aboutBujtina":"About the BUJTINAT LISA",
      "aboutText":"The mountains paradise of warmth, tranquility and restoration",
      "aboutWelcome":"In the Heart of Rugova Mountains, Stunning Scenery",
      "comfortableHoliday": "Comfortable Holiday",
      "exceptionalStay": "Exceptional Stay",
      "enjoyYourStay" : " Enjoy your stay",
      "comfortableRooms": "Comfortable Rooms",
      "unforgettableStay": "Unforgettable Stay",
      "comfortableRoomsAndCabins": "Comfortable Rooms and Cabins",
      "aboutBujtinatLisa": " About Bujtinat Lisa",
      "aboutExperience":"ENJOY YOUR STAY AT THE CABIN",
      "aboutBook1Title":"Exceptional Stay at Bujtinat Lisa",
      "aboutBook1Header":"Comfortable Rooms and Private Cabins",
      "aboutBook2Header":"Stunning Scenery",
      "aboutBook1Text":"Situated in Shkrel, Rugove, Bujtinat Lisa offers a tranquil retreat surrounded by stunning natural beauty. Our accommodations feature air-conditioned rooms with private bathrooms, ensuring a comfortable stay. Guests can enjoy an on-site restaurant, free private parking, and complimentary WiFi. Perfect for families, Bujtinat Lisa also includes a picnic area, making it an ideal destination for relaxation and outdoor activities.",
      "aboutBook2Title":"Unforgettable Stay at Bujtinat Lisa",
      "aboutBook2Text":"Discover the perfect blend of comfort and nature at Bujtinat Lisa. Our rooms and private Cabins offer a peaceful retreat, surrounded by the stunning landscapes of Shkrel, Rugove. Whether you're relaxing in your well-appointed room or exploring the breathtaking surroundings, you'll experience a stay that rejuvenates both body and mind.",
      "aboutExperienceText":"Spend your comfortable holiday in the heart of the beautiful Cabins",
      "footerText":"Located in the picturesque region of Shkrel, Rugove, in the Republic of Kosovo, our property offers a serene retreat surrounded by natural beauty. Bujtinat Lisa provides guests with comfortable rooms equipped with air conditioning and private bathrooms. Enjoy on-site dining, free WiFi, and the convenience of free private parking. Our family-friendly property also features a delightful picnic area for all to enjoy.",
      "aboutWelcomeBottom":"Enjoy Exclusive Offers at Bujtinat Lisa!",
      "bookYourStay":"Book your stay",
      "gastronomy":"Gastronomy",
      "resBars":"Restaurants & Bars",
      "ourBlog":"Our Blog",
      "aboutDiscover":"Culinary At BUJTINAT LISA: Enjoy a various amount of dining options from around the world.",
      "houseRulesTitle": "House rules",
      "houseRulesSpecialRequests": "{{propertyName}} takes special requests - add in the next step!",
      "checkInTitle": "Check-in",
      "checkInDescription": "From 14:00 to 22:00\nYou’ll need to let the property know in advance what time you’ll arrive.",
      "checkOutTitle": "Check-out",
      "checkOutDescription": "From 8:00 to 11:00",
      "cancellationTitle": "Cancellation/ prepayment",
      "cancellationDescription": "Cancellation and prepayment policies vary according to accommodation type.",
      "childrenBedsTitle": "Children and beds",
      "childPolicies": "Child policies",
      "contactus": "Contact Us",
      "childPoliciesDescription": "Children of any age are welcome.\nTo see correct prices and occupancy information, please add the number of children in your group and their ages to your search.",
      "cotAndExtraBedPolicies": "Cot and extra bed policies",
      "cotAndExtraBedDescription": "Cots and extra beds are not available at this property.",
      "noAgeRestrictionTitle": "No age restriction",
      "noAgeRestrictionDescription": "There is no age requirement for check-in.",
      "cashOnlyTitle": "Cash only",
      "cashOnlyDescription": "This property only accepts cash payments.",
      "smokingTitle": "Smoking",
      "smokingDescription": "Smoking is not allowed.",
      "partiesTitle": "Parties",
      "partiesDescription": "Parties/events are allowed.",
      "quietHoursTitle": "Quiet hours",
      "quietHoursDescription": "Guests must be quiet between 22:00 and 8:00.",
      "petsTitle": "Pets",
      "petsDescription": "Pets are allowed on request. Charges may be applicable.",
      "enterDatesLink": "enter the dates of your stay",
      "reserve":"Reserve",
      "from":"From",
      "beds":"Beds",
      "bathroom":"Bathrooms",
 "quantity":"Quantity",
 "size":"Size",
 "exploreHotel": "Explore us",
 "selectVilla" : "Select a Cabin",
 "fullName":"Full Name",
 "villaGallery":"Cabin Gallery",
 "email":"Email",
 "contactNr":"Contact Number",
 "adults":"Adults",
 "children":"Children",




 "type":"Type",
      "night":"Night",
      "roomDetails": {
        "roomDetailsTitle": "Room Details",
        "maxGuests": "Max Guests",
        "bedroomsTitle": "Bedrooms",
        "noVillaData": "No Cabin data available.",
        "amenitiesTitle": "Room Amenities",
        "includedTitle": "What's included in this Cabin?",
        "availabilityTitle": "Availability",
        "applyButton": "Apply",
        "cancelButton": "Cancel",
        "similarRoomsTitle": "Similar Rooms",
        "reservationSuccess": "Reservation completed successfully!",
        "reservationFailed": "Failed to complete reservation. Please try again."
      },
      "roomIncluded": {
        "privateKitchen": "Private kitchen",
        "privateBathroom": "Private bathroom",
        "balcony": "Balcony",
        "gardenView": "Garden view",
        "mountainView": "Mountain view",
        "landmarkView": "Landmark view",
        "innerCourtyardView": "Inner courtyard view",
        "airConditioning": "Air conditioning",
        "spaBath": "Spa bath",
        "patio": "Patio",
        "flatScreenTV": "Flat-screen TV",
        "soundproofing": "Soundproofing",
        "barbecue": "Barbecue",
        "terrace": "Terrace",
        "freeWiFi": "Free WiFi"
      },
      "amenities": {
        "freeToiletries": "Free toiletries",
        "kitchen": "Kitchen",
        "bidet": "Bidet",
        "streamingService": "Streaming service (like Netflix)",
        "toilet": "Toilet",
        "sofa": "Sofa",
        "fireplace": "Fireplace",
        "bathOrShower": "Bath or shower",
        "hardwoodFloors": "Hardwood or parquet floors",
        "towels": "Towels",
        "linen": "Linen",
        "socketNearBed": "Socket near the bed",
        "cleaningProducts": "Cleaning products",
        "tileMarbleFloor": "Tile/marble floor",
        "seatingArea": "Seating Area",
        "privateEntrance": "Private entrance",
        "tv": "TV",
        "slippers": "Slippers",
        "refrigerator": "Refrigerator",
        "satelliteChannels": "Satellite channels",
        "teaCoffeeMaker": "Tea/Coffee maker",
        "iron": "Iron",
        "radio": "Radio",
        "interconnectedRooms": "Interconnected room(s) available",
        "heating": "Heating",
        "hairdryer": "Hairdryer",
        "kitchenette": "Kitchenette",
        "extraTowelsSheets": "Towels/sheets (extra fee)",
        "carpeted": "Carpeted",
        "electricKettle": "Electric kettle",
        "outdoorFurniture": "Outdoor furniture",
        "outdoorDiningArea": "Outdoor dining area",
        "cableChannels": "Cable channels",
        "wardrobeOrCloset": "Wardrobe or closet",
        "oven": "Oven",
        "stovetop": "Stovetop",
        "diningArea": "Dining area",
        "diningTable": "Dining table",
        "upperFloorsStairsOnly": "Upper floors accessible by stairs only",
        "clothesRack": "Clothes rack",
        "dryingRack": "Drying rack for clothing",
        "toiletPaper": "Toilet paper",
        "babySafetyGates": "Baby safety gates",
        "sofaBed": "Sofa bed",
        "handSanitiser": "Hand sanitiser"
      },
      "faq": {
        "title": "FAQs about Bujtinat Lisa",
        "questions": {
          "q1": "Does Bujtinat Lisa have a balcony?",
          "q2": "Does Bujtinat Lisa have a hot tub for its guests?",
          "q3": "What is there to do at Bujtinat Lisa?",
          "q4": "How many bedrooms does Bujtinat Lisa have?",
          "q5": "Does Bujtinat Lisa have a terrace?",
          "q6": "How far is Bujtinat Lisa from the centre of Shkrel?",
          "q7": "What are the check-in and check-out times at Bujtinat Lisa?",
          "q8": "How much does it cost to stay at Bujtinat Lisa?",
          "q9": "How many guests can sleep at Bujtinat Lisa?",
          "q10": "Does Bujtinat Lisa have a restaurant on site?"
        },
        "answers": {
          "a1": "Yes, there are options at this property that have a balcony. You can find out more about this and the other facilities at Bujtinat Lisa on this page.",
          "a2": "Yes, there is a hot tub. You can find out more about this and the other facilities at Bujtinat Lisa on this page.",
          "a3": "Bujtinat Lisa offers the following activities / services (charges may apply):\n- Hot tub/Jacuzzi\n- Cycling\n- Hiking\n- Skiing",
          "a4": "You can choose from a number of accommodation options at Bujtinat Lisa (subject to availability). These have:\n- 1 bedroom\n- 2 bedrooms\n- 3 bedrooms\nFor more detailed information, please check the accommodation option(s) breakdown on this page.",
          "a5": "Yes, there are options at this property that have a terrace. You can find out more about this and the other facilities at Bujtinat Lisa on this page.",
          "a6": "Bujtinat Lisa is 1.6 km from the centre of Shkrel. All distances are measured in straight lines. Actual travel distances may vary.",
          "a7": "Check-in at Bujtinat Lisa is from 14:00, and check-out is until 11:00.",
          "a8": "The prices at Bujtinat Lisa may vary depending on your stay (e.g. dates you select, hotel’s policy etc.). See the prices by entering your dates.",
          "a9": "You can choose from a number of accommodation options at Bujtinat Lisa (subject to availability). These can accommodate:\n- 10 guests\n- 4 guests\n- 6 guests\n- 8 guests\nFor more detailed information, please check the accommodation option(s) breakdown on this page.",
          "a10": "Bujtinat Lisa has 1 restaurant:\n- Restaurant #1"
        }
      }
        }
      },
      al: {
        translation: {
          "v1":"Një arratisje e qetë që ofron dhoma të mëdha dhe komoditete moderne, perfekte për një pushim të qetë",
          "v2":"Një vilë e rehatshme me pamje të mrekullueshme dhe të gjitha komoditetet thelbësore, që ofron një atmosferë të qetë për relaksim.",
          "v3":"Një vilë moderne me dizajn elegant dhe hapësira të hapura, që ofron të gjitha komoditetet për një pushim luksoz.",
          "submit":"Dërgo",
          "years":"Vite Histori",
          "villaGallery":"Galeria e Bujtinave",
          "totalCost":"Kosto totale",
          "adults":"Të rriturit",
          "children":"Fëmijët",
          "email":"Email-i",
          "contactus":"Na kontaktoni",
          "contactNr":"Numri kontaktues",
          "quantity":"Sasia",
          "size":"Madhësia",
          "faq": {
    "title": "Pyetjet e Shpeshta rreth Bujtinave Lisa",
    "questions": {
      "q1": "A ka Bujtinat Lisa një ballkon?",
      "q2": "A ka Bujtinat Lisa mikepritje për mysafirët e saj?",
      "q3": "Çfarë ka për të bërë në Bujtinat Lisa?",
      "q4": "Sa dhoma gjumi ka Bujtinat Lisa?",
      "q5": "A ka Bujtinat Lisa një tarracë?",
      "q6": "Sa larg është Bujtinat Lisa nga qendra e Shkrel?",
      "q7": "Cilat janë oraret e check-in dhe check-out në Bujtinat Lisa?",
      "q8": "Sa kushton të qëndrosh në Bujtinat Lisa?",
      "q9": "Sa mysafirë mund të flenë në Bujtinat Lisa?",
      "q10": "A ka Bujtinat Lisa një restorant në vend?"
    },
    "answers": {
      "a1": "Po, ka opsione në këtë pronë që kanë një ballkon. Ju mund të mësoni më shumë për këtë dhe për pajisjet e tjera në Bujtinat Lisa në këtë faqe.",
      "a2": "Po, ka një mikepritje të ngrohtë. Ju mund të mësoni më shumë për këtë dhe për pajisjet e tjera në Bujtinat Lisa në këtë faqe.",
      "a3": "Bujtinat Lisa ofron aktivitetet/shërbimet e mëposhtme (mund të ketë tarifa):\n- Dush të nxehtë/Jakuzzi\n- Çiklizëm\n- Ecje\n- Ski",
      "a4": "Ju mund të zgjidhni nga disa opsione akomodimi në Bujtinat Lisa (në varësi të disponueshmërisë). Këto kanë:\n- 1 dhomë gjumi\n- 2 dhoma gjumi\n- 3 dhoma gjumi\nPër informacion më të detajuar, ju lutemi kontrolloni përshkrimin e opsionit(ve) të akomodimit në këtë faqe.",
      "a5": "Po, ka opsione në këtë pronë që kanë një tarracë. Ju mund të mësoni më shumë për këtë dhe për pajisjet e tjera në Bujtinat Lisa në këtë faqe.",
      "a6": "Bujtinat Lisa është 1.6 km larg nga qendra e Shkrel. Të gjitha distancat maten në vijë të drejtë. Distancat aktuale të udhëtimit mund të ndryshojnë.",
      "a7": "Check-in në Bujtinat Lisa është nga ora 14:00 dhe check-out është deri në orën 11:00.",
      "a8": "Çmimet në Bujtinat Lisa mund të ndryshojnë në varësi të qëndrimit tuaj (p.sh., datat që zgjidhni, politika e hotelit, etj.). Shikoni çmimet duke futur datat tuaja.",
      "a9": "Ju mund të zgjidhni nga disa opsione akomodimi në Bujtinat Lisa (në varësi të disponueshmërisë). Këto mund të akomodojnë:\n- 10 mysafirë\n- 4 mysafirë\n- 6 mysafirë\n- 8 mysafirë\nPër informacion më të detajuar, ju lutemi kontrolloni përshkrimin e opsionit(ve) të akomodimit në këtë faqe.",
      "a10": "Bujtinat Lisa ka 1 restorant:\n- Restorant #1"
    }
  },
         "type":"Tipi",
          "beds":"Krevata",
          "bathroom":"Banjo",
          "from":"Nga",
          "night":"Nata",
          "roomDetails": {
            "roomDetailsTitle": "Detajet e Dhomës",
            "maxGuests": "Maksimumi i mysafirëve",
            "bedroomsTitle": "Dhoma gjumi",
            "noVillaData": "Nuk ka të dhëna për bujtinën.",
            "amenitiesTitle": "Pajisjet e Dhomës",
            "includedTitle": "Çfarë përfshihet në këtë Bujtinë?",
            "availabilityTitle": "Disponueshmëria",
            "applyButton": "Apliko",
            "cancelButton": "Anulo",
            "similarRoomsTitle": "Dhomat e Ngjashme",
            "reservationSuccess": "Rezervimi u përfundua me sukses!",
            "reservationFailed": "Dështoi për të përfunduar rezervimin. Ju lutem provoni përsëri."
          },
          "reserve":"Rezervo",
          "roomIncluded": {
        "privateKitchen": "Kuzhinë private",
        "privateBathroom": "Banjo private",
        "balcony": "Ballkon",
        "gardenView": "Pamje nga kopshti",
        "mountainView": "Pamje nga malet",
        "landmarkView": "Pamje nga pikat e rëndësishme",
        "innerCourtyardView": "Pamje nga oborri i brendshëm",
        "airConditioning": "Kondicioner",
        "spaBath": "Dush",
        "patio": "Verandë",
        "flatScreenTV": "TV me ekran të sheshtë",
        "soundproofing": "Izolim zhurmash",
        "barbecue": "Barbekju",
        "terrace": "Tarracë",
        "freeWiFi": "WiFi falas"
      },
      "amenities": {
        "freeToiletries": "Tualete falas",
        "kitchen": "Kuzhinë",
        "bidet": "Bide",
        "streamingService": "Shërbim streaming (si Netflix)",
        "toilet": "Tualet",
        "sofa": "Kauç",
        "fireplace": "Oxhak",
        "bathOrShower": "Dush",
        "hardwoodFloors": "Dysheme druri ose parket",
        "towels": "Peshqirë",
        "linen": "Çarçafë",
        "socketNearBed": "Fole pranë shtratit",
        "cleaningProducts": "Produkte pastrimi",
        "tileMarbleFloor": "Dysheme pllaka/mermer",
        "seatingArea": "Zonë e uljes",
        "contactus": "Na Kontaktoni",
        "privateEntrance": "Hyrje private",
        "tv": "TV",
        "slippers": "Pantofla",
        "refrigerator": "Frigorifer",
        "satelliteChannels": "Kanale satelitore",
        "teaCoffeeMaker": "Çaj/Kafe makinë",
        "iron": "Hekur",
        "radio": "Radio",
        "interconnectedRooms": "Dhoma të lidhura në dispozicion",
        "heating": "Ngrohje",
        "hairdryer": "Tharëse flokësh",
        "kitchenette": "Minikuzhinë",
        "extraTowelsSheets": "Peshqirë/çarçafë (me pagesë shtesë)",
        "carpeted": "Tapet",
        "electricKettle": "Kazan elektrik",
        "outdoorFurniture": "Mobilje për jashtë",
        "outdoorDiningArea": "Zonë ngrënie jashtë",
        "cableChannels": "Kanale kabllore",
        "wardrobeOrCloset": "Dollap ose garderobë",
        "oven": "Furrë",
        "stovetop": "Pllakë gatimi",
        "diningArea": "Zonë ngrënie",
        "diningTable": "Tryezë ngrënie",
        "upperFloorsStairsOnly": "Kati i sipërm i arritshëm vetëm me shkallë",
        "clothesRack": "Stendë rrobash",
        "dryingRack": "Stendë tharje rrobash",
        "toiletPaper": "Letër higjienike",
        "babySafetyGates": "Porta sigurie për fëmijë",
        "sofaBed": "Kauç shtrati",
        "handSanitiser": "Dezinfektues duarsh"
      },
      "fullName":"Emri",
          "welcome": "Mirë se vini në Bujtinat Lisa",
          "heroText": "Parajsa juaj e pyllit me ngrohtësi, qetësi dhe rikuperim ju pret.",
          "enquireNow": "Kërkoni tani",
          "checkIn": "Hyrja",
          "checkOut": "Dalja",
          "checkAvailability": "Kontrolloni Disponueshmërinë",
          "checkInTime":"Koha e hyrjes",
          "checkOutTime":"Koha e daljes",
          "welcomeMessage": "Mirë se vini",
          "exploreHotel" : "Eksploroni Bujtinat tona",
          "selectVilla" : "Zgjedhni Bujtinen",
          "mainServices":"Shërbimet kryesore",
          "escapeToMountain":"Arratisuni në malet dhe luginat madhështore ku ëndrrat tuaja bëhen realitet. Zhytuni në qetësinë e natyrës, larg zhurmës dhe nxitimit të jetës së përditshme.",
          "roomsComfortable":"Xhakuzi dhe përvojë qëndrimi e qetë dhe komode.",
          "roomsComfortableText":"Për komoditetin e të gjithë mysafirëve tanë, të gjitha ambientet dhe akomodimet tona janë 100% pa tym – pavarësisht nga substanca apo pajisja. Të gjitha dhomat përfshijnë TV me kabllo, një mini frigorifer, një aparat për kafe, një mikrovalë dhe çarçafë dhe peshqirë falas.",
          "ecl":"Eksploroni bujtinat",
          "roomsOffering":"Duke u ofruar mysafirëve tanë një atmosferë magjike me komoditete moderne.",
          "roomsViews":"Bujtinat dhe pamjet",
          "outdoor":"Jashtë",
          "airportPickUpService": "Shërbimi i marrjes në aeroport",
          "airportPickUpDescription": "Përjetoni një udhëtim pa ndërprerje me shërbimin tonë të dedikuar të marrjes në aeroport, duke siguruar që udhëtimi juaj drejt strehës sonë të jetë po aq komod sa qëndrimi juaj.",
          "exclusiveGuidedTours": "Turne të udhëzuara ekskluzive",
          "exclusiveGuidedToursDescription": "Zhytuni në kulturën lokale dhe peizazhet mahnitëse me turnet tona të personalizuara, të përshtatura për interesat tuaja.",
          "inRoomDining": "Shërbimi i ushqimit në dhomë",
          "inRoomDiningDescription": "Shijoni një përvojë të mrekullueshme ushqimi në komoditetin e dhomës suaj me menunë tonë të gjerë, që përfshin delikatesa lokale dhe ndërkombëtare.",
          "spaAndWellness": "Spa dhe Wellness",
          "spaAndWellnessDescription": "Relaksohuni dhe rifreskohuni me shërbimet tona të spa, që ofrojnë një gamë të trajtimeve të dizajnuara për të përmirësuar mirëqenien tuaj.",
          "adventureActivities": "Aktivitete Aventurë",
          "adventureActivitiesDescription": "Eksploroni natyrën e madhe me aktivitetet tona të kuruara të aventurës, të përshtatshme për dashamirësit e eksitimit dhe natyrës.",
          "conciergeServices": "Shërbimet e Konsierxhit",
          "conciergeServicesDescription": "Ekipi ynë i konsierxhit është këtu për t'ju ndihmuar me të gjitha nevojat tuaja, nga rezervimet e restoranteve deri te planifikimi i ngjarjeve.",
          "moreThanJustAMountainView": "MË SHUMË SE VETËM NJË PAMJE MALET",
          "unforgettableExperiencesAwait": "Përvoja të paharrueshme ju presin",
          "discoverMore": "Zbuloni më shumë",
          "selectYourVilla": "Zgjidhni Bujtinën Tuaj",
          "enjoyAWorldClassStay": "SHIJONI NJË QËNDRIM TË KLASIT BOTËROR",
          "discoverAllActivities": "Zbuloni Të Gjitha Aktivitetet",
          "exploreTheHotel": "Eksploroni hotelin",
            "homeWelcome":"Ne zemër të Shkrelit","escateToMountain":"Ikni ne malet e medha",
            "outdoorAdventures":"Aventurat në Natyrë",
            "mainActivityVilla":"Aktiviteti Kryesor në Bujtine",
            "ShkrelHikingTrails":"Me mbi 1,600 kilometra shtigje, Shkrel ofron disa nga ecjet më mbresëlënëse në rajon.",
            "natureEscapes":"Eksplorim i natyrës",
      "villaRetreat":"Vizitoni Bujtinat tona",
      "curatedNatureTrips":"Zbuloni bukurinë e natyrës me udhëtimet tona të kuruara, të përshtatura për të shfaqur më të mirën e mjedisit lokal.",
      "adventureAwaits":"Aventura ju pret",
      "roomExperience":"Përjetoni Pamje Mahnitëse të Maleve nga Bujtina Jonë Private.",
      "roomExperienceText":"E vendosur në Shkrel, Republika e Kosovës, Bujtinat Lisa ofron një strehim të qetë në zemër të natyrës. E rrethuar nga peizazhe të gjelbra dhe male të larta, bujtinat tona ofrojnë një arratisje perfekte për relaksim dhe rigjenerim, me pamje mahnitëse që kapin bukurinë e Bjeshkëve të Rugovës.",
      "ShkrelAdventurePackages":"Eksploroni bukurinë e ashpër të Shkrelit me paketat tona të aventurave, perfekte për ata që kërkojnë emocione.",
      "ultimateMountainExperience":"Çdo detaj brenda Cabin-it tonë është dizajnuar për t'ju ofruar përvojën e përsosur malore, ku luksin takon natyra.",
      "footerText":"E vendosur në rajonin piktoresk të Shkrelit, Rugovë, në Republikën e Kosovës, prona jonë ofron një strehim të qetë të rrethuar nga bukuria natyrore. Bujtinat Lisa ofron dhoma komode për mysafirët, të pajisura me ajër të kondicionuar dhe banjo private. Shijoni ngrënien në vend, WiFi falas dhe komoditetin e parkimit privat falas. Prona jonë miqësore për familjet gjithashtu ofron një zonë të këndshme pikniku për të gjithë të shijojnë.",
      "about":"Rreth nesh",
      "aboutUs":"Rreth nesh",
      "reach":"Lidhuni",
      "rooms":"Bujtinat",
      "home" : "Kryefaqja",
      "direction":"Merrni drejtimet",
      "gastronomy":"Gastronomi",
      "rsc":"Dhomat, Pamjet dhe Terasat",
      "checkAccomodotion":"Shikoni Bujtinat Tona",
      "selectDate":"Selekto datën",
      "aboutTitle":"NJË BUJTINË IKONIKE",
      "aboutBujtina":"Rreth BUJTINAVE LISA",
      "aboutText":"Parajsa e bjeshkëve me ngrohtësi, qetësi dhe relaksim.",
      "aboutWelcome":"Ne Zemrën e Maleve të Rugovës, Peisazhe Mahnitëse.",
      "comfortableHoliday": "Pushime të rehatshme",
      "exceptionalStay": "Qëndrim i jashtëzakonshëm",
      "comfortableRooms": "Dhoma komode",
      "unforgettableStay": "Qëndrim i paharrueshëm",
      "comfortableRoomsAndCabins": "Bujtina dhe dhoma të rehatshme",
      "enjoyYourStay" : " Shijoni qëndrimin tuaj",
      "aboutBujtinatLisa": " Rreth Bujtinat Lisa",
      "aboutExperienceText":"Kaloni pushimet tuaja komode në ambientin e bujtinave tona",
      "aboutExperience":"SHIJONI QËNDRIMIN TUAJ NË BUJTINË",
      "aboutBook1Title":"Qëndrim i Jashtëzakonshëm në Bujtinat Lisa",
      "aboutBook1Header":"Dhoma Komode në një Ambient Natyror",
      "aboutBook2Header":"Peizazhe Mahnitëse",
      "aboutBook1Text":"E vendosur në Shkrel, Rugovë, Bujtinat Lisa ofron një strehim të qetë të rrethuar nga bukuri natyrore mahnitëse. Akomodimet tona përfshijnë dhoma me ajër të kondicionuar dhe banjo private, duke siguruar një qëndrim komod. Mysafirët mund të shijojnë një restorant në vend, parkim privat falas dhe WiFi falas. Perfekte për familjet, Bujtinat Lisa gjithashtu përfshin një zonë pikniku, duke e bërë atë një destinacion ideal për relaksim dhe aktivitete në natyrë",
      "aboutBook2Title":"Qëndrim i Paharrueshëm në Bujtinat Lisa",
      "aboutBook2Text":"Zbuloni kombinimin e përsosur të komoditetit dhe natyrës në Bujtinat Lisa. Bujtinat tona private ofrojnë një strehim të qetë, të rrethuar nga peizazhet mahnitëse të Shkrelit, Rugovë. Qoftë duke u relaksuar në bujtinën tuaj të mirëpajisur apo duke eksploruar rrethinën e mahnitshme, do të përjetoni një qëndrim që rigjallëron trupin dhe mendjen.",
      "bookYourStay":"Rezervoni qëndrimin tuaj",
      "aboutWelcomeBottom":"Shijoni ofertat ekskluzive në Bujtinat Lisa!",
      "resBars":"Restaurantet dhe Baret",
      "ourBlog":"Blogu ynë",
      "aboutDiscover":"Kulinar në BUJTINAT LISA: Shijoni një gamë të gjerë opsionesh të ngrënies nga e gjithë bota.",
      "houseRulesTitle": "Rregullat e Bujtinave",
      "houseRulesSpecialRequests": "{{propertyName}} pranon kërkesa speciale - shtoni në hapin tjetër!",
      "checkInTitle": "Hyrja",
      "checkInDescription": "Nga ora 14:00 deri në 22:00\nDo t'ju duhet të njoftoni pronën paraprakisht se kur do të arrini.",
      "checkOutTitle": "Dalja",
      "checkOutDescription": "Nga ora 8:00 deri në 11:00",
      "cancellationTitle": "Anulimi/ parapagimi",
      "cancellationDescription": "Politikat e anulimit dhe parapagimit ndryshojnë sipas llojit të akomodimit.",
      "childrenBedsTitle": "Fëmijët dhe shtretërit",
      "childPolicies": "Politikat për fëmijët",
      "childPoliciesDescription": "Fëmijët e çdo moshe janë të mirëpritur.\nPër të parë çmimet e sakta dhe informacionin e zënies, ju lutemi shtoni numrin e fëmijëve në grupin tuaj dhe moshat e tyre në kërkimin tuaj.",
      "cotAndExtraBedPolicies": "Politikat për shtretërit e foshnjave dhe shtretërit shtesë",
      "cotAndExtraBedDescription": "Shtretër për foshnje dhe shtretër shtesë nuk janë në dispozicion në këtë pronë.",
      "noAgeRestrictionTitle": "Pa kufizim moshe",
      "noAgeRestrictionDescription": "Nuk ka kërkesë moshe për check-in.",
      "cashOnlyTitle": "Vetëm me para në dorë",
      "cashOnlyDescription": "Kjo pronë pranon vetëm pagesa në dorë.",
      "smokingTitle": "Duhani",
      "smokingDescription": "Nuk lejohet pirja e duhanit.",
      "partiesTitle": "Festa",
      "partiesDescription": "Festa/ndodhi  lejohen.",
      "quietHoursTitle": "Orët e qeta",
      "quietHoursDescription": "Mysafirët duhet të jenë të qetë midis orës 22:00 dhe 8:00.",
      "petsTitle": "Kafshët shtëpiake",
      "petsDescription": "Kafshët shtëpiake lejohen me kërkesë. Mund të aplikohen tarifa.",
      "enterDatesLink": "vendosni datat e qëndrimit tuaj",
      
      "testimonial1": {
        "content": "Vend i mrekullueshëm për të qëndruar me një familje. Pëlqeva shumë dizajnin e kabinës Pamje të mrekullueshme të maleve, pyllit. Pëlqeva shumë ballkonin dhe barbekjun. Dhoma të bukura dhe vend i rehatshëm për qëndrim, vend për të ngrënë",
        "label": "Eveli"
      },
      "testimonial2": {
        "content": "Akomodim shumë i mirë, shumë i pastër. Pak i vogël për 8 persona, ata kanë 2 krevate dopio me një krevat shtesë tërheqës, d.m.th. 6 persona flenë rehat atje.",
        "label": "Kukaj"
      },
      "testimonial3": {
        "content": "Akomodim shumë i mirë, shumë i pastër. Pak i vogël për 8 persona, ata kanë 2 krevate dopio me një krevat shtesë tërheqës, d.m.th. 6 persona flenë rehat atje.",
        "label": "Pascal"
      }
        }
      }
    },
    detection: {
      order: ['localStorage', 'cookie', 'htmlTag', 'navigator', 'path', 'subdomain'],
      lookupLocalStorage: 'i18nextLng',
      lookupCookie: 'i18nextLng',
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'],
      cookieMinutes: 10,
      cookieDomain: 'myDomain',
      htmlTag: document.documentElement
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false
    }
  });


export default i18n;

