import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import "./Faq.scss";
import Navbar from '../../Components/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const faqData = [
  {
    question: 'faq.questions.q1',
    answer: 'faq.answers.a1',
  },
  {
    question: 'faq.questions.q2',
    answer: 'faq.answers.a2',
  },
  {
    question: 'faq.questions.q3',
    answer: 'faq.answers.a3',
  },
  {
    question: 'faq.questions.q4',
    answer: 'faq.answers.a4',
  },
  {
    question: 'faq.questions.q5',
    answer: 'faq.answers.a5',
  },
  {
    question: 'faq.questions.q6',
    answer: 'faq.answers.a6',
  },
  {
    question: 'faq.questions.q7',
    answer: 'faq.answers.a7',
  },
  {
    question: 'faq.questions.q8',
    answer: 'faq.answers.a8',
  },
  {
    question: 'faq.questions.q9',
    answer: 'faq.answers.a9',
  },
  {
    question: 'faq.questions.q10',
    answer: 'faq.answers.a10',
  },
];

const FAQComponent = () => {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState(null); // Track which FAQ is open

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle between open and closed
  };

  return (
    <div>
      <Navbar />
      <div className='faq'>
        <div className='container'>
          <div className="faq-container">
            <h1>{t('faq.title')}</h1>
            {faqData.map((faq, index) => (
              <div key={index} className="faq-item">
                <button 
                  className="faq-question" 
                  onClick={() => toggleAnswer(index)}
                >
                  <h2>{t(faq.question)}</h2>
                  <span className="arrow">
                    <FontAwesomeIcon icon={openIndex === index ? faChevronUp : faChevronDown} />
                  </span>
                </button>
                {openIndex === index && (
                  <div className="faq-answer">{t(faq.answer)}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQComponent;
