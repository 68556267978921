
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import RoomDetails from './Pages/RoomDetails/RoomDetails';
import Room from './Pages/Room/Room';
import About from './Pages/About/About';
import Blog from './Pages/Blog/Blog';
import Footer from './Components/Header/Footer/Footer';
import FAQComponent from './Pages/Faq/Faq';
import ScrollToTopButton from './Components/Scroll/ScrollToTopButton';
import HouseRules from './Pages/Rules/HouseRules';

function App() {
  return (
    <div className="App">
      <Router>
      <Routes>
  <Route path="/" element={<Home />} />
  <Route path="/about" element={<About />} />
  <Route path="/rooms" element={<Room />} />
  <Route path="/blog" element={<Blog />} />
  <Route path="/faq" element={<FAQComponent />} />
  <Route path="/roomDetails/:villaId" element={<RoomDetails />} />
</Routes>

      </Router>
      <ScrollToTopButton/>
      <div className='about_rules'>
      <div className='container'>
      <HouseRules/>
      </div>
      </div>
<Footer/>
    </div>
  );
}

export default App;
