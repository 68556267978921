import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Header/Header";
import "./RoomDetails.scss";
import Button from "../../Components/Button/Button";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation,useParams  } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faUserGroup, faBed, faToilet, faChevronRight, faCarOn, faSwimmingPool, faBath, faCoffee, faTv, faShower, faTachometerAlt, faAngleLeft, faAngleRight, faCouch } from "@fortawesome/free-solid-svg-icons";
import BookingForm from "../../Components/Forms/ReservationForm/ReservationForm";
import { DateRangePicker, defaultStaticRanges, defaultInputRanges, createStaticRanges } from "react-date-range";
import { addDays, addMonths, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Slider from "react-slick";
import { ENDPOINTS, apiCall } from '../../Lib/App';
import { Link } from 'react-router-dom';
import { Alert, Spinner } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const RoomDetails = () => {
  const { t, i18n } = useTranslation();
  const { villaId } = useParams(); // Get villaId from URL params
  const [villas, setVillas] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success'); 
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [bookedDates, setBookedDates] = useState([]);
  const [villaData, setVillaData] = useState(null); // State for villa data

  const location = useLocation();
  const villaDataFromState = location.state?.villaData;

  useEffect(() => {
    if (villaDataFromState) {
      setVillaData(villaDataFromState);
      fetchBookedDates(villaDataFromState.villa_id);
    } else if (villaId) {
      fetchVillaById(villaId); // Fetch villa if not in state
    }
  }, [villaDataFromState, villaId]);


  const fetchVillaById = async (id) => {
    try {
      const endpoint = buildEndpoint(ENDPOINTS.getVilla, id);
      const response = await apiCall(endpoint);
      if (response) {
        console.log(response,"Terer")
        setVillaData(response);
        fetchBookedDates(id); 
      }
    } catch (error) {
      console.error("Failed to fetch villa by ID", error);
    }
  };

  useEffect(() => {
    if (villaData) {
      window.scrollTo(0, 0);
    }
  }, [villaData]);

  const fetchVillas = async () => {
    try {
      const endpoint = ENDPOINTS.getAllVillas;
      const response = await apiCall(endpoint);
      if (response && Array.isArray(response.villas)) {
        const parsedVillas = response.villas.map(villa => ({
          ...villa,
        }));
        setVillas(parsedVillas);
      } else {
        setVillas([]);
      }
    } catch (error) {
      console.error("Failed to fetch villas", error);
      setVillas([]);
    }
  };

  useEffect(() => {
    fetchVillas();
  }, []);

  

  const fetchBookedDates = async (villaId) => {
    try {
      const endpoint = buildEndpoint(ENDPOINTS.getBookedDates, villaId);
      const response = await apiCall(endpoint);
      if (response && response.bookedDates) {
        const disabledDates = response.bookedDates.flatMap((booking) => {
          const start = new Date(booking.start_date);
          const end = new Date(booking.end_date);
          const dates = [];
          for (let d = new Date(start); d < end; d.setDate(d.getDate() + 1)) {
            dates.push(new Date(d));
          }
          return dates;
        });
        setBookedDates(disabledDates);
      }
    } catch (error) {
      console.error('Error fetching booked dates', error);
    }
  };

  const buildEndpoint = (endpoint, id) => {
    const clonedEndpoint = { ...endpoint };
    clonedEndpoint.url = clonedEndpoint.url.replace("{id}", id);
    return clonedEndpoint;
  };

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
  };

  const showAlert = (message, type = "success") => {
    setAlertMessage(message);
    setAlertType(type);
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 5000);
  };

  const handleSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const endpoint = ENDPOINTS.addBooking;
      const response = await apiCall(endpoint, { data: formData });
      if (response) {
        showAlert(t('roomDetails.reservationSuccess'));
      }
    } catch (error) {
      console.error("Error adding booking", error);
      showAlert(t('roomDetails.reservationFailed'), "danger");
    } finally {
      setIsLoading(false);
    }
  };

  if (!villaData) {
    return <p>{t('roomDetails.loading')}</p>; // Show loading message if villa data is not loaded yet
  }

  const roomIncluded = [
    { text: t("roomIncluded.privateKitchen"), icon: faCoffee },
    { text: t("roomIncluded.privateBathroom"), icon: faBath },
    { text: t("roomIncluded.balcony"), icon: faExpand },
    { text: t("roomIncluded.gardenView"), icon: faTv },
    { text: t("roomIncluded.mountainView"), icon: faShower },
    { text: t("roomIncluded.landmarkView"), icon: faTachometerAlt },
    { text: t("roomIncluded.innerCourtyardView"), icon: faBed },
    { text: t("roomIncluded.airConditioning"), icon: faToilet },
    { text: t("roomIncluded.spaBath"), icon: faSwimmingPool },
    { text: t("roomIncluded.patio"), icon: faCarOn },
    { text: t("roomIncluded.flatScreenTV"), icon: faTv },
    { text: t("roomIncluded.soundproofing"), icon: faChevronRight },
    { text: t("roomIncluded.barbecue"), icon: faBed },
    { text: t("roomIncluded.terrace"), icon: faToilet },
    { text: t("roomIncluded.freeWiFi"), icon: faShower }
  ];

  const amenities = [
    { text: t("amenities.freeToiletries"), icon: faShower },
    { text: t("amenities.kitchen"), icon: faCoffee },
    { text: t("amenities.bidet"), icon: faBath },
    { text: t("amenities.streamingService"), icon: faTv },
    { text: t("amenities.toilet"), icon: faToilet },
    { text: t("amenities.sofa"), icon: faBed },
    { text: t("amenities.fireplace"), icon: faCarOn },
    { text: t("amenities.bathOrShower"), icon: faShower },
    { text: t("amenities.hardwoodFloors"), icon: faChevronRight },
    { text: t("amenities.towels"), icon: faBed },
    { text: t("amenities.linen"), icon: faBed },
    { text: t("amenities.socketNearBed"), icon: faTachometerAlt },
    { text: t("amenities.cleaningProducts"), icon: faShower },
    { text: t("amenities.tileMarbleFloor"), icon: faChevronRight },
    { text: t("amenities.seatingArea"), icon: faCouch },
    { text: t("amenities.privateEntrance"), icon: faExpand },
    { text: t("amenities.tv"), icon: faTv },
    { text: t("amenities.slippers"), icon: faTachometerAlt },
    { text: t("amenities.refrigerator"), icon: faCoffee },
    { text: t("amenities.satelliteChannels"), icon: faTv },
    { text: t("amenities.teaCoffeeMaker"), icon: faCoffee },
    { text: t("amenities.iron"), icon: faTachometerAlt },
    { text: t("amenities.radio"), icon: faTv },
    { text: t("amenities.interconnectedRooms"), icon: faExpand },
    { text: t("amenities.heating"), icon: faTv },
    { text: t("amenities.hairdryer"), icon: faTachometerAlt },
    { text: t("amenities.kitchenette"), icon: faCoffee },
    { text: t("amenities.extraTowelsSheets"), icon: faBed },
    { text: t("amenities.carpeted"), icon: faChevronRight },
    { text: t("amenities.electricKettle"), icon: faCoffee },
    { text: t("amenities.outdoorFurniture"), icon: faExpand },
    { text: t("amenities.outdoorDiningArea"), icon: faExpand },
    { text: t("amenities.cableChannels"), icon: faTv },
    { text: t("amenities.wardrobeOrCloset"), icon: faChevronRight },
    { text: t("amenities.oven"), icon: faTachometerAlt },
    { text: t("amenities.stovetop"), icon: faCoffee },
    { text: t("amenities.diningArea"), icon: faCouch },
    { text: t("amenities.diningTable"), icon: faChevronRight },
    { text: t("amenities.upperFloorsStairsOnly"), icon: faExpand },
    { text: t("amenities.clothesRack"), icon: faTachometerAlt },
    { text: t("amenities.dryingRack"), icon: faExpand },
    { text: t("amenities.toiletPaper"), icon: faToilet },
    { text: t("amenities.babySafetyGates"), icon: faTachometerAlt },
    { text: t("amenities.sofaBed"), icon: faBed },
    { text: t("amenities.handSanitiser"), icon: faChevronRight },
  ];

  const bedrooms = villaData.bedrooms ? (typeof villaData.bedrooms === 'string' ? JSON.parse(villaData.bedrooms) : villaData.bedrooms) : [];

  const customStaticRanges = createStaticRanges([
    ...defaultStaticRanges.filter((range) => !["Yesterday", "Last Week", "Last Month"].includes(range.label)),
    {
      label: "Next Week",
      range: () => ({
        startDate: startOfWeek(addDays(new Date(), 7), {
          weekStartsOn: 1,
        }),
        endDate: endOfWeek(addDays(new Date(), 7), { weekStartsOn: 1 }),
      }),
      isSelected: (range) =>
        range.startDate.getTime() ===
          startOfWeek(addDays(new Date(), 7), {
            weekStartsOn: 1,
          }).getTime() &&
        range.endDate.getTime() ===
          endOfWeek(addDays(new Date(), 7), {
            weekStartsOn: 1,
          }).getTime(),
    },
    {
      label: "Next Month",
      range: () => ({
        startDate: startOfMonth(addMonths(new Date(), 1)),
        endDate: endOfMonth(addMonths(new Date(), 1)),
      }),
      isSelected: (range) => range.startDate.getTime() === startOfMonth(addMonths(new Date(), 1)).getTime() && range.endDate.getTime() === endOfMonth(addMonths(new Date(), 1)).getTime(),
    },
  ]);

  const customInputRanges = defaultInputRanges.filter((range) => !["days up to today", "days starting today"].includes(range.label));


  const handleApply = () => {
    // Handle apply
  };

  const handleCancel = () => {
    // Handle cancel
  };

  const ArrowLeft = (props) => (
    <button {...props} className={"prev-arrow"}>
      {" "}
      <FontAwesomeIcon icon={faAngleLeft} />
    </button>
  );

  const ArrowRight = (props) => (
    <button {...props} className={"next-arrow"}>
      {" "}
      <FontAwesomeIcon icon={faAngleRight} />
    </button>
  );

  const gallerySettings = {
    dots: true,
    fade: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };




  const language = i18n.language;
  const description = villaData.description[language] || villaData.description['en']; 
  const name = villaData.name[language] || villaData.name['en'];
  const truncatedDescription = description.length > 120
    ? `${description.substring(0, 120)}...`
    : description;

  return (
    <div>
      <Navbar />
 
      <section className="room_hero">
        <Slider {...gallerySettings}>
          {villaData.images.map((img, index) => (
            <div key={index}>
              <img src={img} alt="villa image" style={{ width: "100%" }} />
            </div>
          ))}
        </Slider>
      </section>
      <section className="room_details">
        <div className="container">
          <div className="room_details-wrapper">
            <div className="room_details-content">
              <h2>{name}</h2>
              <div>{villaData.area_size} m2/ {t('roomDetails.maxGuests')} {villaData.max_guests}</div>
              <div className="room_details_info">
                <div className="room_details_info-item">
                  <FontAwesomeIcon icon={faExpand} />
                  <span>{villaData.area_size}</span>
                </div>
                <div className="room_details_info-item">
                  <FontAwesomeIcon icon={faUserGroup} />
                  <span>{villaData.max_guests} {t('roomDetails.maxGuests')}</span>
                </div>
                <div className="room_details_info-item">
                  <FontAwesomeIcon icon={faBed} />
                  <span>{villaData.number_of_beds} {t('beds')}</span>
                </div>
                <div className="room_details_info-item">
                  <FontAwesomeIcon icon={faToilet} />
                  <span>{villaData.number_of_bathrooms} {t('bathroom')}</span>
                </div>
              </div>
              <div className="bedrooms_info">
                <h3>{t('roomDetails.bedroomsTitle')}</h3>
                <div className="bedroom-data">
                {bedrooms.map((bedroom, index) => (
                  <div key={index} className="bedroom">
                    <h4>{bedroom.name}</h4>
                    <div  className="bedroom-inner">
                      {bedroom.beds.map((bed, bedIndex) => (
                    
                        <div key={bedIndex} >
                          <p><span> {t('quantity')}:</span>{bed.quantity}</p>
                          <p><span> {t('size')}:</span>{bed.size}</p>
                          <p><span> {t('type')}:</span>{bed.type}</p>
                          </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              </div>
              <p>{description}</p>
    
            </div>
            <div className="room_details-form">
            {alertVisible && (
        <Alert variant={alertType} onClose={() => setAlertVisible(false)} dismissible>
          {alertMessage}
        </Alert>
      )}
              <BookingForm villaData={villaData} onSubmit={handleSubmit} bookedDates={bookedDates} />
              {isLoading && <Spinner animation="border" role="status" className="spinner">
                <span className="sr-only">Loading...</span>
              </Spinner>}
            </div>
          </div>
        </div>
      </section>
      <section className="room_amenities">
        <div className="container">
          <div className="room_amenities-wrapper">
            <h3>{t('roomDetails.amenitiesTitle')}</h3>
            <div className="room_amenities-container">
              {amenities.map((item, index) => (
                <div className="room_amenities-item" key={index}>
                  <FontAwesomeIcon icon={item.icon} />
                  <span>{item.text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="room_included">
        <div className="container">
          <div className="room_included-wrapper">
            <h2>{t('roomDetails.includedTitle')}</h2>
            <ul>
              {roomIncluded.map((item, index) => (
                <li key={index}>
                  <FontAwesomeIcon icon={item.icon} />
                  <span>{item.text}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <section className="room_datePicker">
     
        <div className="room_details-form-mobile">
          <BookingForm villaData={villaData} onSubmit={handleSubmit} />
          {isLoading && <Spinner animation="border" role="status" className="spinner">
            <span className="sr-only">Loading...</span>
          </Spinner>}
        </div>
        <div className="container">
          <div className="room_datePicker-wrapper">
            <h2>{t('roomDetails.availabilityTitle')}</h2>
            <DateRangePicker
              ranges={dateRange}
              onChange={handleSelect}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              direction="horizontal"
              className="preview-area"
              staticRanges={customStaticRanges}
              inputRanges={customInputRanges}
              disabledDates={bookedDates}
              weekStartsOn={1}
            />
            <div className="room_datePicker-actions">
              <Button className="cancel-btn" onClick={handleCancel} icon="" buttonText={t('roomDetails.cancelButton')} />
              <Button className="apply-btn" onClick={handleApply} icon="" buttonText={t('roomDetails.applyButton')} />
            </div>
          </div>
        </div>
      </section>
      <section className="roomDetails2_similar">
        <div className="container">
          <div className="roomDetails2_similar-area">
            <h2>{t('roomDetails.similarRoomsTitle')}</h2>
            <div className="roomDetails2_similar-content">
              <div className="roomDetails2_similar-lists">
                {villas.slice(-3).map((room, roomIndex) => {
                  const language = i18n.language;
                  const description = room.description[language] || room.description['en'];
                  const name = room.name[language] || room.name['en'];
                  const truncatedDescription = description.length > 120
                    ? `${description.substring(0, 120)}...`
                    : description;
                  return (
                    <div className="item-card" key={roomIndex}>
                  
                           <Link onClick={() => window.scrollTo(0, 0)} to={`/roomDetails/${room.villa_id}`} state={{ villaData: room }} key={roomIndex} >
                        <div className="roomDetails2_similar_price">€{room.price_per_night} / Night</div>
                        <div
                          className="item-card-hover"
                          style={{
                            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('${room.images[0]}')`,
                            backgroundSize: 'cover',
                          }}
                        >
                          <div className="card-image">
                            <div className="hover-content">
                              <div className="hover-text">
                                <p>€{room.price_per_night} / NIGHT</p>
                                <p>{truncatedDescription}</p>
                                <a href="#" className="discover-more">
                                  {t("discoverMore")}
                                </a>
                              </div>
                            </div>
                            <div className="card-details">
                              <h2>{name}</h2>
                              <p>{room.area_size}m² / Mountain View / {room.max_guests} Guests</p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RoomDetails;
