import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Header/Header";
import "./Blog.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { faChevronRight, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HomeImg from "../../Assets/home.jpg";
import HomeFav from "../../Assets/01-04.jpg";

// Import images as variables
import Img1 from "../../Assets/1.jpg";
import Img2 from "../../Assets/2.jpg";
import Img3 from "../../Assets/3.jpg";
import Img4 from "../../Assets/4.jpg";
import Img5 from "../../Assets/5.jpg"; 
import Img7 from "../../Assets/01-04.jpg"; 
import Img8 from "../../Assets/8.jpg"; 
import Img9 from "../../Assets/9.jpg"; 
import Img11 from "../../Assets/11.jpg"; 
import Img12 from "../../Assets/12.jpg"; 
import Img13 from "../../Assets/13.jpg"; 
import Img15 from "../../Assets/15.jpg"; 
import Img16 from "../../Assets/16.jpg"; 
import Img17 from "../../Assets/17.jpg"; 
import Img18 from "../../Assets/18.jpg"; 
import Img20 from "../../Assets/20.jpg"; 
import Img21 from "../../Assets/21.jpg"; 
import Img22 from "../../Assets/22.jpg"; 
import Img23 from "../../Assets/23.jpg"; 
import Img24 from "../../Assets/24.jpg"; 
import Img25 from "../../Assets/25.jpg"; 
import Img26 from "../../Assets/26.jpg"; 
import Img27 from "../../Assets/27.jpg"; 
import Img28 from "../../Assets/28.jpg"; 
import Img29 from "../../Assets/29.jpg"; 

const Blog = () => {
  const [villas, setVillas] = useState([]);
  const { t, i18n } = useTranslation();

  const ArrowLeft = (props) => (
    <button {...props} className={"prev-arrow"}>
      <FontAwesomeIcon icon={faAngleLeft} />
    </button>
  );

  const ArrowRight = (props) => (
    <button {...props} className={"next-arrow"}>
      <FontAwesomeIcon icon={faAngleRight} />
    </button>
  );

  // Array of static images
  const staticImages = [
    Img1,
    Img2,
    Img3,
    Img4,
    Img5,
    Img7,
    Img8,
    Img9,
    Img11,
    Img12,
    Img13,
    Img15,
    Img16,
    Img17,
    Img18,
    Img20,
    Img21,
    Img22,
    Img23,
    Img24,
    Img25,
    Img26,
    Img27,
    Img28,
    Img29,
  ];

  const villaSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    rlt: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    fetchVillas();
  }, []);

  const fetchVillas = async () => {
    // Fetch villas from backend
    try {
      // Mocked API response
      const response = { villas: [] }; 
      if (response && Array.isArray(response.villas)) {
        setVillas(response.villas);
      } else {
        setVillas([]);
      }
    } catch (error) {
      console.error("Failed to fetch villas", error);
      setVillas([]);
    }
  };

  return (
    <div>
      <Navbar />
      <section className="Blog_hero" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HomeImg})` }}>
        <div className="container">
          <div className="Blog_hero_content">
            <p>{t("aboutText")}</p>
            <h2>{t("ourBlog")}</h2>
          </div>
        </div>
      </section>
  
      <section className="home_gallery_villa blog_gallery">
        <div className="container">
          <div className="home_gallery_top">
            <h1>{t("unforgettableExperiencesAwait")}</h1>
            <p> {t("roomsOffering")}</p>
          </div>
        </div>
        <div style={{padding:"0px 5px"}}>
          <Slider {...villaSettings}>
            {staticImages.map((image, index) => (
              <div key={index} className="villa-image">
                <img src={image} alt={`Villa ${index + 1}`} />
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <section className="image-grid-section">
        <div className="container">
  <div className="image-grid">
    <div className="image-item">
      <img src={HomeFav} alt="Description of Image 1" />
      <p>{t("v2")}</p>
    </div>
    <div className="image-item">
      <img src={Img12} alt="Description of Image 2" />
      <p>{t("v1")}</p>
    </div>
    <div className="image-item">
      <img src={Img4} alt="Description of Image 3" />
      <p>{t("v3")}</p>
    </div>
  </div>
  </div>
</section>


    
    </div>
  );
};

export default Blog;
