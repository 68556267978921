import React from 'react';
import './Button.scss'; 


const Button = ({ className, onClick, icon, buttonText }) => {
  return (
    <button className={` ${className}`} onClick={onClick}>
      {icon && React.cloneElement(icon, { className: 'button-icon' })} {buttonText}
    </button>
  );
};

export default Button;
